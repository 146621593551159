/** @format */

import { reactive, toRefs } from '@vue/composition-api'
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

const state = reactive({
	reportDocumentOurCompany: '',
	reportDocumentNumber: '',
	reportDocumentType: '',
	reportProcessType: '', // ? add or view
	isSelectedDocumentLocked: false,
	isSelectedDocumentReported: false,
	isModalVisible: false,
	isUnlockButtonVisible: true,
	keyProcessReport: 1,
	keyGetReportData: 1,
	keyReportButtons: 1,
	showPdf: false,
	showGetData: false,
	reportCustomer: '',
	reportCustomerEmail: '',
	reportDocumentDate: '',
	reportDocumentStatus: '',
	// ** QR Code fields
	qrString: '',
	qr_nme: '',
	qr_cid: '',
	qr_iref: '',
	qr_idt: '',
	qr_ddt: '',
	qr_pt: '',
	qr_due: '',
	qr_acc: '',
	// todo ----------------------------
	slicedOcrNumber: '',
	sendDocumentBy: '',
	hasValidEmail: '',
	documentDefinition: '',
	showProcessReportEmail: false,
})

export default function useProcessReportStore() {
	return {
		state,
		...toRefs(state),
	}
}
