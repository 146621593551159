<!-- @format -->

<template>
	<div>
		<div><hr /></div>
		<div v-if="isUnlockButtonVisible">
			<b-button
				:disabled="!!isSelectedDocumentReported"
				class="margin-top-15"
				@click="unlockDocument"
				style="width: 250px; background-color: orange; color: white"
				><i class="fas fa-unlock" aria-hidden="true"></i>&nbsp; Unlock the
				{{ reportDocumentType }}</b-button
			>
		</div>
	</div>
</template>

<script>
import UnlockOrderMutation from './UnlockOrderMutation.graphql'
import UnlockDispatchMutation from './UnlockDispatchMutation.graphql'
import UnlockInvoiceMutation from './UnlockInvoiceMutation.graphql'
import { useMutation } from '@vue/apollo-composable'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import Store from '@/store'

export default {
	name: 'ProcessReportUnlockDocument',
	setup() {
		const {
			reportDocumentType,
			reportDocumentNumber,
			reportDocumentOurCompany,
			showPdf,
			isModalVisible,
			isUnlockButtonVisible,
			isSelectedDocumentReported,
		} = useProcessReportStore()
		// *** Unlock ---------------------------------------------------------------------------------
		const { mutate: unlockOrder, onDone: onDoneUnlockOrder } = useMutation(
			UnlockOrderMutation,
			() => ({
				variables: {
					company_id: reportDocumentOurCompany.value,
					order_number: reportDocumentNumber.value,
				},
			}),
		)
		onDoneUnlockOrder((result) => console.log(result))
		// todo -----------------------------------------------------------------------------------------
		const { mutate: unlockDispatch, onDone: onDoneUnlockDispatch } =
			useMutation(UnlockDispatchMutation, () => ({
				variables: {
					company_id: reportDocumentOurCompany.value,
					dispatch_number: reportDocumentNumber.value,
				},
			}))
		onDoneUnlockDispatch((result) => console.log(result))
		// todo -----------------------------------------------------------------------------------------
		const { mutate: unlockInvoice, onDone: onDoneUnlockInvoice } = useMutation(
			UnlockInvoiceMutation,
			() => ({
				variables: {
					our_company: reportDocumentOurCompany.value,
					invoice_number: reportDocumentNumber.value,
				},
			}),
		)
		onDoneUnlockInvoice((result) => console.log(result))
		// todo -----------------------------------------------------------------------------------------
		const unlockDocument = () => {
			switch (reportDocumentType.value) {
				case 'order':
					unlockOrder()
					break
				case 'dispatch':
					unlockDispatch()
					break
				case 'sale-invoice':
					unlockInvoice()
					break
				case 'reminder':
					// code block
					break
				default:
					alert('Nothing to unlock')
			}
			showPdf.value = false
			isModalVisible.value = false
			Store.dispatch('setKeyTable', Store.getters.getKeyTable + 1)
		}
		// todo -----------------------------------------------------------------------------------------

		return {
			unlockDocument,
			isUnlockButtonVisible,
			reportDocumentType,
			isSelectedDocumentReported,
		}
	},
}
</script>

<style lang="scss" scoped></style>
