<!-- @format -->

<template>
	<b-navbar class="sub-navbar">
		<template slot="brand">
			<b-dropdown aria-role="list">
				<button
					class="button btnMenu is-info"
					slot="trigger"
					slot-scope="{ active }"
					style="width: 250px"
				>
					<span>{{ props.page }} Menu</span>
					<b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
				</button>
				<b-navbar-item
					v-for="item in navbarSource"
					:key="item.linkText"
					tag="router-link"
					:to="{ name: item.routeName }"
					style="width: 250px"
				>
					{{ item.linkText }}
				</b-navbar-item>
			</b-dropdown>
		</template>
		<template slot="burger">
			<b-navbar-item> </b-navbar-item>
		</template>
	</b-navbar>
</template>

<script>
// import { reactive } from '@vue/composition-api'
import userLang from '@/composables/i18n/user-i18n'
import useSubNavbarState from './useSubNavbarState'

export default {
	name: 'SubNavbar',
	components: {},
	props: {
		page: String,
	},
	setup(props) {
		const {
			startupNavbarData,
			accountantNavbarData,
			orderNavbarData,
			dispatchNavbarData,
			deliveryNavbarData,
			invoiceNavbarData,
			purchaseInvoiceNavbarData,
			reminderNavbarData,
			transactionNavbarData,
			stockNavbarData,
			companyNavbarData,
			adminNavbarData,
			devNavbarData,
			accountingNavbarData,
			//****
			salesInvoiceNavbarData,
			marketingNavbarData,
			utilitiesNavbarData,
			customerNavbarData,
		} = useSubNavbarState()
		let navbarSource
		switch (props.page) {
			case 'Startup':
				navbarSource = startupNavbarData.value
				break
			case 'Accountant':
				navbarSource = accountantNavbarData.value
				break
			case 'Utilities':
				navbarSource = utilitiesNavbarData.value
				break
			case 'Order':
				navbarSource = orderNavbarData.value
				break
			case 'Dispatch':
				navbarSource = dispatchNavbarData.value
				break
			case 'Delivery':
				navbarSource = deliveryNavbarData.value
				break
			case 'Invoice':
				navbarSource = invoiceNavbarData.value
				break
			case 'Purchase Invoice':
				navbarSource = purchaseInvoiceNavbarData.value
				break
			case 'Reminder':
				navbarSource = reminderNavbarData.value
				break
			case 'Transaction':
				navbarSource = transactionNavbarData.value
				break
			case 'Accounting':
				navbarSource = accountingNavbarData.value
				break
			case 'Stock':
				navbarSource = stockNavbarData.value
				break
			case 'Company':
				navbarSource = companyNavbarData.value
				break
			case 'Admin':
				navbarSource = adminNavbarData.value
				break
			case 'Dev':
				navbarSource = devNavbarData.value
				break
			//*****
			case 'Sales Invoice':
				navbarSource = salesInvoiceNavbarData.value
				break
			case 'Marketing':
				navbarSource = marketingNavbarData.value
				break
			case 'Customer':
				navbarSource = customerNavbarData.value
				break
		}

		return {
			...userLang(),
			navbarSource,
			props,
		}
	},
}
</script>

<style scoped>
.sub-navbar {
	background-color: beige;
	z-index: 4000;
}
</style>
