/** @format */

// Format numbers to use in reports and forms
import numeral from 'numeral'

export default function useReportNumber() {
	// Format values
	numeral.defaultFormat('0,0.00')
	// Format numbers to/from form fields
	// From form fields to float number
	const cleaveToFloat = (str) => {
		return parseFloat(str.replaceAll(',', '.').replaceAll(' ', ''))
	}
	const cleaveToInt100 = (str) => {
		console.log('cleaveToInt100 str', typeof str)
		const convertedString = typeof str === 'string' ? str : String(str)
		console.log('cleaveToInt100 convertedString', typeof convertedString)
		const checkedStr = convertedString.length > 0 ? convertedString : '0.00'
		console.log('cleaveToInt100 checkedStr', typeof checkedStr)
		return Math.round(
			parseFloat(checkedStr.replaceAll(',', '.').replaceAll(' ', '')) * 100,
		)
	}
	// From float number to cleave form field
	const floatToCleave = (float) => {
		return String(Math.round(float * 100) / 100).replaceAll('.', ',')
	}
	// Format numbers from Store to database
	const floatToInt100 = (float) => {
		return Math.round(float * 100)
	}
	// Format numbers that fetched from database
	const roundedReportNumber = (numberToReport) => {
		return Math.round(numberToReport / 100)
	}
	const intToFloatReport = (numberToFloatReport) => {
		return (parseFloat(numberToFloatReport) / 100).toFixed(2)
	}
	// From database to form
	const intToFloatCleave = (numberToFloatReport) => {
		return String(
			(parseFloat(numberToFloatReport) / 100).toFixed(2),
		).replaceAll('.', ',')
	}
	const intToFloatWithoutCurrency = (number) => {
		return numeral(number / 100).format()
	}
	const addCurrency = (number, currency = 'kr.') => {
		return numeral(number).format() + ' ' + currency
	}
	const roundedCurrencyAddedNumber = (number, currency) => {
		return addCurrency(roundedReportNumber(number), currency)
	}
	const floatedCurrencyAddedNumber = (number, currency) => {
		return addCurrency(intToFloatReport(number), currency)
	}
	// Accounting functions
	const getSalesInvoiceTotal = (payload) => {
		return roundedReportNumber(
			payload.invoice_totals_aggregate.aggregate.sum.line_price_total_credit +
				payload.invoice_totals_aggregate.aggregate.sum.vat_credit,
		)
	}
	const getPurchaseInvoiceTotal = (payload) => {
		return roundedReportNumber(
			payload.invoice_totals_aggregate.aggregate.sum.line_price_total_debit +
				payload.invoice_totals_aggregate.aggregate.sum.vat_debit,
		)
	}
	const invoiceToPay = (payload) => {
		const salesInvoiceToDb = getSalesInvoiceTotal(payload) * 100
		const purchaseInvoiceToDb = getPurchaseInvoiceTotal(payload) * 100
		let correctedToPay
		if (payload.transaction_type === 'sales invoice ') {
			correctedToPay = addCurrency(getSalesInvoiceTotal(payload))
		} else {
			correctedToPay = addCurrency(getPurchaseInvoiceTotal(payload))
		}
		return { correctedToPay, salesInvoiceToDb, purchaseInvoiceToDb }
	}
	const invoicePayment = (payload) => {
		const salesInvoicePayment =
			payload.invoice_payments_aggregate.aggregate.sum.payment_credit || 0
		const purchaseInvoicePayment =
			payload.invoice_payments_aggregate.aggregate.sum.payment_debit || 0
		let correctedPayment
		if (payload.transaction_type === 'sales invoice ') {
			correctedPayment = roundedCurrencyAddedNumber(salesInvoicePayment)
		} else {
			correctedPayment = roundedCurrencyAddedNumber(purchaseInvoicePayment)
		}
		return {
			correctedPayment,
			salesInvoicePaymentToDb: salesInvoicePayment,
			purchaseInvoicePaymentToDb: purchaseInvoicePayment,
		}
	}
	const invoiceBalance = (payload) => {
		const saleInvoiceBalance =
			getSalesInvoiceTotal(payload) * 100 -
			payload.invoice_payments_aggregate.aggregate.sum.payment_credit
		const purchaseInvoiceBalance =
			getPurchaseInvoiceTotal(payload) * 100 -
			payload.invoice_payments_aggregate.aggregate.sum.payment_debit
		let correctedBalance
		if (payload.transaction_type === 'sales invoice ') {
			correctedBalance = addCurrency(getSalesInvoiceTotal(payload))
		} else {
			correctedBalance = addCurrency(getPurchaseInvoiceTotal(payload))
		}
		return {
			correctedBalance,
			saleInvoiceBalanceToDb: saleInvoiceBalance,
			purchaseInvoiceBalanceToDb: purchaseInvoiceBalance,
		}
	}
	return {
		roundedReportNumber,
		intToFloatReport,
		intToFloatCleave,
		addCurrency,
		roundedCurrencyAddedNumber,
		floatedCurrencyAddedNumber,
		invoiceToPay,
		invoicePayment,
		invoiceBalance,
		cleaveToFloat,
		floatToCleave,
		cleaveToInt100,
		floatToInt100,
		intToFloatWithoutCurrency,
	}
}
