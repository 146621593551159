/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not share the state
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import { reactive } from '@vue/composition-api'
// *** Import functions to format currency and numbers ***
// import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber'
// *** Get the data from store ***
import Store from '@/store'

const getOrderReportData = () => {
	const documentData = Store.getters.getPdfReportData
	const documentOurCompany = Store.getters.getPdfReportData.company
	const documentCustomer = Store.getters.getPdfReportData.customer
	const documentCustomerAddress =
		Store.getters.getPdfReportData.order_headers_dispatch_address_addresses_rel
	const documentLines =
		Store.getters.getPdfReportData.order_heders_goods_transactions_rel
	// const { roundedCurrencyAddedNumber } = useReportNumber()
	const documentLanguage = documentData.order_language
	const exc = documentData.order_exchange_unit

	let documentTypeTag
	let dateIssuedTag
	// let dueDateTag
	let customerNumberTag
	let fromTag
	let ourRefTag
	let addressTag
	let toTag
	let yourRefTag
	let orgNumberTag
	let remarksTag
	let contactTag
	let telTag
	let emailTag
	let productUnitTag
	let qtyTag
	let pageTag
	let ofTag
	let signatureName
	let jobTitle
	let routeIdTag
	let openHoursTag
	switch (documentLanguage) {
		case 'en':
			documentTypeTag = `Order`
			dateIssuedTag = 'Issued Date'
			customerNumberTag = 'Customer Number'
			fromTag = 'From'
			ourRefTag = 'Our ref: '
			addressTag = 'Address'
			toTag = 'To'
			yourRefTag = 'Your ref: '
			orgNumberTag = 'Tax. Number: '
			remarksTag = 'Remarks'
			contactTag = 'Contact '
			telTag = 'Tel: '
			emailTag = ' Email: '
			productUnitTag = 'item - amount'
			qtyTag = 'Qty'
			pageTag = 'page'
			ofTag = ' of'
			signatureName = 'Your name'
			jobTitle = 'Your job title'
			routeIdTag = 'Route id'
			openHoursTag = 'Open Hours:'
			break
		default:
			documentTypeTag = `Order`
			dateIssuedTag = 'Datum'
			customerNumberTag = 'Kundnummer'
			fromTag = 'Från'
			ourRefTag = 'Vår Ref: '
			addressTag = 'Adress'
			toTag = 'Till'
			yourRefTag = 'Err Ref: '
			orgNumberTag = 'Org Nummer: '
			remarksTag = 'Anteckningarna'
			contactTag = 'Kontakt '
			telTag = 'Tel: '
			emailTag = ' Epost: '
			productUnitTag = 'artikel -enhet'
			qtyTag = 'antal'
			pageTag = 'sida'
			ofTag = ' av'
			signatureName = 'Ditt namn'
			jobTitle = 'Din arbetstitel'
			routeIdTag = 'Rutt id'
			openHoursTag = 'Öppettider: '
	}

	const data = {
		// headerLeft: 'myLeftHeader',
		// headerCenter: 'myCenterHeader',
		// headerRight: 'myRightHeader',
		// footerLeft: 'myLeftFooter',
		// footerCenter: 'myCenterFooter',
		// footerRight: 'myRightFooter',
		logo: documentOurCompany.company_logo_64base,
		watermark: documentOurCompany.company_watermark_64base,
		documentType: documentTypeTag,
		documentNumber: documentData.order_number,
		dateIssued: documentData.order_date,
		dateIssuedLabel: dateIssuedTag,
		customerNumber: documentCustomer.customer_id,
		customerNumberLabel: customerNumberTag,
		routeId: documentCustomerAddress._cities?.route_id_by_city,
		routeIdLabel: routeIdTag,
		from: fromTag,
		billingFromLabel: fromTag,
		ourRef: ourRefTag,
		reference: documentOurCompany.company_reference,
		ourCompany: documentOurCompany.company_name,
		addressLabel: addressTag,
		addressLine1:
			documentOurCompany.company_address_line_1 +
			' ' +
			documentOurCompany.company_address_line_2,
		addressLine2:
			documentOurCompany.company_post_code +
			' ' +
			documentOurCompany.company_city,
		addressLine3:
			documentOurCompany.company_state +
			' ' +
			documentOurCompany.company_country,
		addressLine4: '',
		orgNum: documentOurCompany.company_org_num,
		to: toTag,
		billingToLabel: toTag,
		yourRef: yourRefTag,
		toReference: documentCustomer.customer_reference,
		clientCompany: documentCustomer.customer_title,
		clientNickName: documentCustomer.customer_nickname,
		toAddressLine1:
			documentCustomerAddress.line_1 + ' ' + documentCustomerAddress.line_2,
		toAddressLine2:
			documentCustomerAddress.post_code + ' ' + documentCustomerAddress.city,
		toAddressLine3:
			documentCustomerAddress.state + ' ' + documentCustomerAddress.country,
		toAddressLine4: '',
		toOrgNum: documentCustomer.customer_org_num,
		documentLines: documentLines,
		orgNumberLabel: orgNumberTag,
		notesLabel: remarksTag,
		notesLine1:
			contactTag +
			telTag +
			documentOurCompany.company_tel +
			emailTag +
			documentOurCompany.company_email,
		productUnitLable: productUnitTag,
		qty: qtyTag,
		page: pageTag,
		of: ofTag,
		exchange: exc,
	}
	const rowsItems = []
	rowsItems.push([
		{
			text: data.productUnitLable,
			style: 'itemsHeader',
		},
		{
			text: data.qty,
			style: ['itemsHeader', 'center'],
		},
	])
	for (let item of data.documentLines) {
		rowsItems.push([
			{
				text: item.line_info,
				style: 'itemSubTitle',
			},
			{
				text: item.order_amount,
				style: 'itemNumberC',
			},
		])
	}
	const docDefinition = reactive({
		pageSize: 'A4',
		pageOrientation: 'portrait',
		pageMargins: [50, 20, 40, 30],
		// header: {
		//   columns: [
		//     { text: data.headerLeft, style: "documentHeaderLeft" },
		//     { text: data.headerCenter, style: "documentHeaderCenter" },
		//     { text: data.headerRight, style: "documentHeaderRight" }
		//   ]
		// },
		// footer: {
		//   columns: [
		//     { text: data.footerLeft, style: "documentFooterLeft" },
		//     { text: data.footerCenter, style: "documentFooterCenter" },
		//     { text: data.footerCenter, style: "documentFooterRight" }
		//   ]
		// },
		footer: function (currentPage, pageCount) {
			return [
				{
					text: data.page + currentPage.toString() + data.of + pageCount,
					style: 'documentFooterRight',
				},
			]
		},
		background: function () {
			return {
				image: data.watermark,
				opacity: 0.3,
				alignment: 'center',
				height: 264,
				margin: [0, (842 - 264) / 2, 0, 0],
			}
		},
		content: [
			// Header
			{
				columns: [
					{
						image: data.logo,
						width: 150,
					},
					[
						{
							text: data.documentType,
							style: 'documentTitle',
							width: '*',
						},
						{
							stack: [
								{
									columns: [
										{
											text: data.documentType + ' #',
											style: 'documentSubTitle',
											width: '*',
										},
										{
											text: data.documentNumber,
											style: 'documentSubValue',
											width: 100,
										},
									],
								},
								{
									columns: [
										{
											text: data.dateIssuedLabel,
											style: 'documentSubTitle',
											width: '*',
										},
										{
											text: data.dateIssued,
											style: 'documentSubValue',
											width: 100,
										},
									],
								},
								{
									columns: [
										{
											text: data.customerNumberLabel,
											style: 'documentSubTitle',
											width: '*',
										},
										{
											text: data.customerNumber,
											style: 'documentSubValue',
											width: 100,
										},
									],
								},
								{
									columns: [
										{
											text: data.routeIdLabel,
											style: 'documentSubTitle',
											width: '*',
										},
										{
											text: data.routeId,
											style: 'documentSubValue',
											width: 100,
										},
									],
								},
							],
						},
					],
				],
			},
			// Billing Headers
			{
				columns: [
					{
						text: data.billingFromLabel,
						style: 'documentBillingTitle',
					},
					{
						text: data.billingToLabel,
						style: 'documentBillingTitle',
					},
					// {
					//   text: data.billingFromLabel,
					//   style: 'documentBillingTitle',
					// },
				],
			},
			// Billing Details
			{
				columns: [
					{
						text: data.ourCompany,
						style: 'documentBillingCompanyDetails',
					},
					{
						text: data.clientCompany,
						style: 'documentBillingCompanyDetails',
					},
					// {
					//   text: data.ourCompany,
					//   style: 'documentBillingCompanyDetails',
					// },
				],
			},
			{
				columns: [
					{
						text: data.ourRef + data.reference,
						style: 'documentBillingDetails',
					},
					{
						text: data.yourRef + data.toReference,
						style: 'documentBillingDetails',
					},
					// {
					//   text: data.ourRef + data.reference,
					//   style: 'documentBillingDetails',
					// },
				],
			},
			{
				columns: [
					{
						text: '',
						style: 'documentBillingDetails',
					},
					{
						text: data.clientNickName,
						style: 'documentBillingDetails',
					},
					// {
					//   text: '',
					//   style: 'documentBillingDetails',
					// },
				],
			},
			// Billing Address Title
			{
				columns: [
					{
						text: data.addressLabel,
						style: 'documentBillingAddressTitle',
					},
					{
						text: data.addressLabel,
						style: 'documentBillingAddressTitle',
					},
					// {
					//   text: data.addressLabel,
					//   style: 'documentBillingAddressTitle',
					// },
				],
			},
			// Billing Address
			{
				columns: [
					{
						text:
							data.addressLine1 +
							' \n ' +
							data.addressLine2 +
							' \n ' +
							data.addressLine3 +
							' \n ',
						style: 'documentBillingAddress',
					},
					{
						text:
							data.toAddressLine1 +
							' \n ' +
							data.toAddressLine2 +
							' \n ' +
							data.toAddressLine3 +
							' \n ',
						style: 'documentBillingAddress',
					},
					// {
					//   text:
					//     data.addressLine1 +
					//     ' \n ' +
					//     data.addressLine2 +
					//     ' \n ' +
					//     data.addressLine3 +
					//     ' \n ',
					//   style: 'documentBillingAddress',
					// },
				],
			},
			// Org. Number
			{
				columns: [
					{
						text: data.orgNumberLabel + data.orgNum,
						style: 'organizationNumber',
					},
					{
						text: data.orgNumberLabel + data.toOrgNum,
						style: 'organizationNumber',
					},
					// {
					//   text: data.orgNumberLabel + data.orgNum,
					//   style: 'organizationNumber',
					// },
				],
			},
			// Line breaks
			'\n\n',
			// Items
			{
				table: {
					// headers are automatically repeated if the table spans over multiple pages
					// you can declare how many rows should be treated as headers
					headerRows: 1,
					widths: ['*', 40],
					style: 'tableHeader',
					body: rowsItems,
				},
			},
			// Signature
			{
				columns: [
					{
						text: '',
					},
					{
						stack: [
							{
								text: '_________________________________',
								style: 'signaturePlaceholder',
							},
							{
								text: signatureName,
								style: 'signatureName',
							},
							{
								text: jobTitle,
								style: 'signatureJobTitle',
							},
						],
						width: 180,
					},
				],
			},
			{
				text: data.notesLabel,
				style: 'notesTitle',
			},
			{
				text:
					data.notesLine1 +
					' \n ' +
					openHoursTag +
					' ' +
					(documentCustomerAddress?.open_hours
						? documentCustomerAddress?.open_hours
						: ''),
				style: 'notesText',
			},
		],
		styles: {
			// Document Header
			documentHeaderLeft: {
				fontSize: 10,
				margin: [5, 5, 5, 5],
				alignment: 'left',
			},
			documentHeaderCenter: {
				fontSize: 10,
				margin: [5, 5, 5, 5],
				alignment: 'center',
			},
			documentHeaderRight: {
				fontSize: 10,
				margin: [5, 5, 5, 5],
				alignment: 'right',
			},
			// Document Footer
			documentFooterLeft: {
				fontSize: 10,
				margin: [5, 5, 5, 5],
				alignment: 'left',
			},
			documentFooterCenter: {
				fontSize: 10,
				margin: [5, 5, 5, 5],
				alignment: 'center',
			},
			documentFooterRight: {
				fontSize: 10,
				margin: [5, 5, 40, 5],
				alignment: 'right',
			},
			// Document Title
			documentTitle: {
				fontSize: 22,
				bold: true,
				alignment: 'right',
				margin: [0, 0, 0, 15],
			},
			// Document Details
			documentSubTitle: {
				fontSize: 12,
				alignment: 'right',
			},
			documentSubValue: {
				fontSize: 12,
				alignment: 'right',
			},
			// Billing Headers
			documentBillingTitle: {
				fontSize: 12,
				bold: true,
				alignment: 'left',
				margin: [50, 20, 0, 5],
			},
			// Billing Details
			documentBillingCompanyDetails: {
				alignment: 'left',
				fontSize: 11,
				bold: true,
				margin: [50, 0, 0, 0],
			},
			documentBillingDetails: {
				alignment: 'left',
				fontSize: 10,
				margin: [50, 0, 0, 0],
			},
			documentBillingAddressTitle: {
				margin: [50, 2, 0, 1],
				bold: true,
				fontSize: 10,
			},
			documentBillingAddress: {
				fontSize: 10,
				margin: [50, 0, 0, 0],
			},
			organizationNumber: {
				margin: [50, 4, 0, 3],
				bold: true,
				fontSize: 10,
			},
			// Items Header
			itemsHeader: {
				margin: [0, 5, 0, 5],
				bold: true,
				fontSize: 10,
			},
			// Item Title
			itemTitle: {
				bold: true,
				fontSize: 10,
			},
			itemSubTitle: {
				italics: true,
				fontSize: 10,
			},
			itemNumberTotal: {
				margin: [0, 5, 10, 5],
				alignment: 'right',
				fontSize: 10,
			},
			itemNumberPrice: {
				margin: [2, 5, 2, 5],
				alignment: 'right',
				fontSize: 10,
			},
			itemNumberC: {
				margin: [0, 5, 0, 5],
				alignment: 'center',
				fontSize: 10,
			},
			itemTotal: {
				margin: [0, 5, 0, 5],
				bold: true,
				alignment: 'center',
				fontSize: 10,
			},

			// Items Footer (Subtotal, Total, Tax, etc)
			itemsFooterSubTitle: {
				margin: [0, 5, 0, 5],
				bold: true,
				alignment: 'right',
				fontSize: 10,
			},
			itemsFooterSubValue: {
				margin: [0, 5, 13, 5],
				bold: true,
				alignment: 'right', // center
				fontSize: 10,
			},
			itemsFooterTotalTitle: {
				margin: [0, 5, 0, 5],
				bold: true,
				alignment: 'right',
				fontSize: 10,
			},
			itemsFooterTotalValue: {
				margin: [0, 5, 12, 5], // *******************
				bold: true,
				alignment: 'right',
				fontSize: 10,
			},
			signaturePlaceholder: {
				margin: [0, 70, 0, 0],
				fontSize: 10,
			},
			signatureName: {
				bold: true,
				alignment: 'center',
				fontSize: 10,
			},
			signatureJobTitle: {
				italics: true,
				fontSize: 10,
				alignment: 'center',
			},
			notesTitle: {
				fontSize: 10,
				bold: true,
				margin: [0, 50, 0, 3],
			},
			notesText: {
				fontSize: 10,
			},
			center: {
				alignment: 'center',
			},
			itemVat: {
				margin: [2, 5, 2, 5],
				alignment: 'right',
				fontSize: 10,
			},
			itemVatAmount: {
				margin: [0, 5, 14, 5],
				alignment: 'right',
				fontSize: 10,
			},
		},
		tableHeader: {
			bold: true,
			alignment: 'center',
			fontSize: 10,
		},
		defaultStyle: {
			columnGap: 20,
		},
	})
	return docDefinition
}
export default function useOrderDocDefinition() {
	return {
		getOrderReportData,
	}
}
