<!-- @format -->

<template>
	<div class="margin-top-30 margin-bottom-10" style="color: white">
		<span class="text-bold"></span>
		<span class="text-bold">Customer:</span> &nbsp;{{ props.customer }}<br />
		<span class="text-bold">Document:</span> &nbsp;{{ props.fileName }}<br />
		<span class="text-bold">Date:</span> &nbsp;{{ props.reportDocumentDate
		}}<br />
		<span class="text-bold">Document Status:</span> &nbsp;{{
			props.reportDocumentStatus
		}}<br />
	</div>
</template>

<script>
export default {
	name: 'ProcessReportHeaders',
	props: {
		customer: {
			type: String,
		},
		fileName: {
			type: String,
		},
		reportDocumentDate: {
			type: String,
		},
		reportDocumentStatus: {
			type: String,
		},
	},
	setup(props) {
		return { props }
	},
}
</script>

<style lang="scss" scoped></style>
