<!-- @format -->

<template>
	<div>
		<EmailPopup
			:closePopup="closePopup"
			:disableCloseButton="disableClose"
			:sendToDropbox="sendToDropbox"
		>
			<template #header>
				<div>
					{{ formName }}
				</div>
			</template>
			<template #body>
				<div>
					<div style="margin-left: 40px">
						<ProcessReportHeaders
							:reportCustomer="reportCustomer"
							:fileName="fileName"
							:reportDocumentDate="reportDocumentDate"
							:reportDocumentStatus="reportDocumentStatus"
						/>
					</div>
					<div>
						<b-button
							class="is-success margin-top-30"
							@click="emailPDF"
							style="width: 250px; margin-left: 30px"
							><i class="fas fa-envelope-square"></i>&nbsp;
							{{ buttonTag }}</b-button
						>
					</div>
				</div>
			</template>
			<template #footer>
				<div>
					{{ formName }}
				</div>
			</template>
		</EmailPopup>
	</div>
</template>

<script>
import EmailPopup from '@/_srcv2/pages/_reports/create-report/EmailPopup.vue'
import { onMounted, ref, computed } from '@vue/composition-api'
import useInvoiceDocDefinition from '../get-data-and-report/invoice/invoiceDocDefinition.js'
import useReminderDocDefinition from '../get-data-and-report/reminder/report/reminderDocDefinition.js'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import ProcessReportHeaders from '@/_srcv2/pages/_reports/create-report/ProcessReportHeaders.vue'
import Store from '@/store'
import axios from 'axios'
// todo: Comment following 2 lines before deploy in prod mode!!!

// todo --------------------------------------------------------

export default {
	name: 'ProcessReportEmail',
	components: {
		EmailPopup,
		ProcessReportHeaders,
	},
	props: {
		reportHeaders: Object,
		isToSave: {
			type: Boolean,
			default: false,
		},
		closeModal: {
			type: Function,
			default: () => {},
		},
		type: {
			type: String,
			default: '',
		},
	},
	setup(props) {
		// todo -------------------------------------------------------------
		const {
			reportCustomer,
			reportDocumentDate,
			reportDocumentStatus,
			reportDocumentType,
			qrString,
			slicedOcrNumber,
			showProcessReportEmail,
		} = useProcessReportStore()
		// todo -------------------------------------------------------------
		const rh = props.reportHeaders

		const mailServerUrl = computed(() => {
			if (reportDocumentType.value === 'sale-invoice') {
				return process.env.VUE_APP_MAIL_SERVER_INVOICE_URL
			} else if (reportDocumentType.value === 'reminder') {
				return process.env.VUE_APP_MAIL_SERVER_REMINDER_URL
			}
		})
		const mailSubject = computed(() => {
			if (reportDocumentType.value === 'sale-invoice') {
				return 'Ni har en ny faktura => '
			} else if (reportDocumentType.value === 'reminder') {
				return 'Ni har en ny påminnelse => '
			}
		})
		// todo ----------------------------------------------------------------
		onMounted(() => {
			init()
		})
		// todo ----------------------------------------------------------------------------
		const { getInvoiceReportData } = useInvoiceDocDefinition()
		const { getReminderReportData } = useReminderDocDefinition()
		const getDocumentDefinition = () => {
			switch (reportDocumentType.value) {
				case 'sale-invoice':
					return getInvoiceReportData()
				case 'reminder':
					return getReminderReportData()
				default:
				// code block
			}
		}
		// todo ----------------------------------------------------------------------------------------
		// ** Get file name
		const fileName = String(rh.reportType + '-' + rh.reportNumber)
			.trim()
			.replaceAll(' ', '')
			.toLowerCase()
		// ! --------------------------------------------------------------
		// todo: Create stream and send it to backend
		// ** define tags
		const relatedDocs = ref('Relaterade följesedlar')

		// ** define formData
		let formData = new FormData()
		// ** create stream-------------------------------------------------
		const createStream = (documentDefinition) => {
			return new Promise((resolve, reject) => {
				if (documentDefinition !== undefined || null) {
					const pdfMake = require('pdfmake/build/pdfmake.js')
					const pdfFonts = require('pdfmake/build/vfs_fonts.js')
					pdfMake.vfs = pdfFonts.pdfMake.vfs
					setTimeout(() => {
						const pdfDocGenerator = pdfMake.createPdf(documentDefinition)
						pdfDocGenerator.getBlob((blob) => {
							formData.set('fileName', fileName)
							formData.set('pdfFileStream', blob, 'blob.pdf')
						})
					}, 1000)
					resolve(formData)
				} else {
					const errorObject = {
						msg: "The stream couldn't be created",
					}
					reject(errorObject)
				}
			})
		}
		// * -----------------------------------------------------------
		// ** init function
		const toMail = ref()
		const disableClose = ref()
		const formName = ref()
		const buttonTag = ref()
		const init = () => {
			const reportData = computed(() => Store.getters.getPdfReportData)
			console.log('reportData.value', reportData.value)
			formData.delete('pdfFileStream')
			for (const key of formData.keys()) {
				formData.delete(key)
			}
			// todo -------------------------------------------------------------
			disableClose.value = props.isToSave ? true : false
			toMail.value = props.isToSave
				? process.env.VUE_APP_DROPBOX_MAIL_ADDRESS
				: reportData.value.customer.customer_email
			buttonTag.value = props.isToSave ? 'Save to dropbox' : 'Email'
			formName.value = props.isToSave
				? 'Confirm to save the Document to Dropbox'
				: 'Confirm Sending Document via Email to Customer'
			// ! ---------------------------------------------------
			let dd = getDocumentDefinition(rh.reportType)
			const relatedDispatchesNumbersArray =
				Store.getters.getPdfReportData.related_dispatches.map(
					(item) => item.dispatch_number,
				)
			const relatedDispatches = String(
				relatedDispatchesNumbersArray,
			).replaceAll(',', ', ')

			formData.set(
				'ourMail',
				`Kontakt: ${Store.getters.getOurCompanies.company_finans_email}`,
			)
			formData.set('email', Store.getters.getOurCompanies.company_finans_email)
			formData.set('bcc', Store.getters.getOurCompanies.company_finans_email)
			formData.set('ourCompany', reportData.value.company.company_name)
			formData.set('customerMail', toMail.value)
			formData.set('reference', 'Finans Avdelning')
			formData.set('subject', mailSubject.value)
			formData.set('tel', reportData.value.company.company_tel)
			formData.set('invoiceNumber', reportData.value.invoice_number)
			formData.set(
				'reminderNumber',
				reportData.value?.reminder_lines?._reminder_headers?.reminder_id || '',
			)

			formData.set('slicedOcr', slicedOcrNumber.value)
			formData.set(
				'relatedDocuments',
				`${relatedDocs.value}: ${relatedDispatches}`,
			)
			formData.set('invoiceDueDate', reportData.value.invoice_due_date)
			formData.set(
				'reminderDueDate',
				reportData.value?.reminder_lines?._reminder_headers
					?.reminder_due_date || '',
			)
			formData.set('toPay', dd.content[10].stack[0].table.body[0][1].text)
			formData.set('bg', reportData.value.company.company_bank_giro)
			formData.set('message', 'Ni kan kontakta med oss via epost')
			formData.set('qrString', qrString.value)
			createStream(dd).then((formData) => {
				console.log('**** form data', ...formData.entries())
				// console.log('createStream is fired')
			})
		}
		// todo end -------------------------------------------------------
		const emailPDF = () => {
			axios({
				method: 'post',
				url: mailServerUrl.value,
				data: formData,
			})
				.then(function (response) {
					// todo handle success
					alert(response.data.status)
				})
				.catch(function (err) {
					//todo handle error
					alert(err)
				})
			showProcessReportEmail.value = false
			props.closeModal()
		}
		// todo Save to Dropbox -------------------------------------------------------
		const mailServerUrlDropbox = computed(() => {
			return process.env.VUE_APP_DROPBOX_MAIL_ADDRESS
		})
		const sendToDropbox = () => {
			formData.set('customerMail', mailServerUrlDropbox.value)
			emailPDF()
		}
		// todo Modal ------------------------------------------------------------
		const closePopup = () => {
			showProcessReportEmail.value = false
		}
		return {
			reportCustomer,
			reportDocumentDate,
			reportDocumentStatus,
			fileName,
			emailPDF,
			formName,
			closePopup,
			buttonTag,
			disableClose,
			sendToDropbox,
		}
	},
}
</script>

<style scoped></style>
