/** @format */
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

// ** -----------------------------------------------------------------------
// todo Get sale-invoice lines total total
let correctedTotal
let roundingAmount
let creditTag
let freeTag
let documentTypeTag
const calculateToPay = (documentData, documentLines, docType = 'invoice') => {
	if (docType === 'reminder') {
		console.log('useCalculateToPayFunctions documentData', documentData)
		const correctedTotal = parseInt(
			documentData.reminder_lines._reminder_headers
				.reminder_headers_reminder_lines_rel_aggregate.aggregate.sum
				.invoice_balance / 100,
		)
		return { correctedTotal }
	} else {
		console.log('calculateToPay documentLines', documentLines)
		console.log('calculateToPay documentData', documentData)
		const documentLanguage = documentData.invoice_language
		// const exc = documentData.invoice_exchange_unit
		const invoiceType = documentData.invoice_type
		// ? lines total total
		const invoicesLinesTotalArr = documentLines.map((item) =>
			documentData.invoice_type === 'Inland'
				? item.line_price_total_credit
				: item.line_price_total_credit_exchange,
		)
		if (documentData.is_fee_addable === true) {
			invoicesLinesTotalArr.push(documentData.paper_invoice_fee)
		}
		console.log('invoicesLinesTotalArr', invoicesLinesTotalArr)
		// todo ----------------------------------------------------------
		const getInvoiceSubTotal = invoicesLinesTotalArr.reduce(function (
			result,
			item,
		) {
			return result + item
		},
		0)
		console.log('sales sale-invoice subtotal', getInvoiceSubTotal)
		// todo ----------------------------------------------------------------------------
		// ? Get lines vat total
		// ************** sale-invoice type göre exc
		const invoicesLinesVatTotalArr = documentLines.map((item) =>
			documentData.invoice_type === 'Inland'
				? item.vat_credit
				: item.vat_credit_exchange,
		)
		if (documentData.is_fee_addable === true) {
			invoicesLinesVatTotalArr.push(documentData.paper_invoice_fee_vat)
		}
		console.log('invoicesLinesVatTotalArr', invoicesLinesVatTotalArr)
		// ----------------------------------------------------------
		const getSumVat = invoicesLinesVatTotalArr.reduce(function (result, item) {
			return result + item
		}, 0)
		console.log('sales sale-invoice getSumVat', getSumVat)
		// todo ----------------------------------------------------------------------------
		// ? TO PAY
		let toPay = (getSumVat + getInvoiceSubTotal) / 100
		console.log('TO PAY', toPay)
		let rounding = toPay - parseInt(toPay)
		if (rounding < 0.5) {
			correctedTotal = parseInt(toPay)
			roundingAmount = -1 * rounding
		} else {
			correctedTotal = parseInt(toPay) + 1
			roundingAmount = 1 - rounding
		}
		// todo ----------------------------------------------------------------------------
		// ? Get sale-invoice vat array
		let vat12 = 0
		let vat25 = 0
		const invoicesLinesVatArr = documentLines.map((item) => [
			item.vat_percent,
			item.vat_credit,
		])
		console.log(
			'************* ======>>>>> invoicesLinesVatArr',
			invoicesLinesVatArr,
		)
		invoicesLinesVatArr.forEach((item) => {
			if (item[0] === 25) {
				vat25 = vat25 + item[1]
			} else if (item[0] === 12) {
				vat12 = vat12 + item[1]
			}
		})
		vat25 =
			documentData.is_fee_addable === true
				? vat25 + documentData.paper_invoice_fee_vat
				: vat25 + 0
		console.log('vat12', vat12)
		console.log('vat25', vat25)
		let invoiceVatArr = []
		if (vat25 !== 0) {
			invoiceVatArr.push({ vat: '25%', vatAmount: vat25 })
		}
		if (vat12 !== 0) {
			invoiceVatArr.push({ vat: '12%', vatAmount: vat12 })
		}
		if (vat25 === 0 && vat12 === 0) {
			invoiceVatArr.push({ vat: '0%', vatAmount: 0 })
		}
		// todo ----------------------------------------------------------------------------
		// ? Get invoice_price
		const getInvoicePrice = (item) => {
			return documentData.invoice_type === 'Inland'
				? item.invoice_price
				: item.invoice_price_exchange
		}
		// todo ----------------------------------------------------------------------------
		// ? Get line_price_total_credit
		const getLinePriceTotalCredit = (item) => {
			return documentData.invoice_type === 'Inland'
				? item.line_price_total_credit
				: item.line_price_total_credit_exchange
		}
		// todo ----------------------------------------------------------------------
		// ? Get creditTag
		const getCreditTag = () => {
			switch (documentLanguage) {
				case 'en':
					return toPay < 0 ? 'Credit ' : ''
				default:
					return toPay < 0 ? 'Kredit ' : ''
			}
		}
		creditTag = getCreditTag()
		// todo --------------------------------------------------------------------------
		// ? get momsfri tag
		let result
		const getVatFreeTag = () => {
			switch (invoiceType) {
				case 'Inland':
					for (let i = 0; i < invoicesLinesVatArr.length; i++) {
						if (
							invoicesLinesVatArr[i][0] === 25 ||
							invoicesLinesVatArr[i][0] === 12
						) {
							result = ''
							break
						} else {
							result = 'Momsfri '
						}
					}
					return result
				default:
					return documentLanguage === 'se' ? 'Momsfri ' : 'VAT-free '
			}
		}
		freeTag = getVatFreeTag()
		// todo --------------------------------------------------------------------------
		const getDocumentTypeTag = () => {
			switch (invoiceType) {
				case 'Inland':
					return ''
				default:
					return documentLanguage === 'se' ? 'Utländsk ' : 'Overseas '
			}
		}
		documentTypeTag = getDocumentTypeTag()
		return {
			creditTag, // *
			freeTag, // *
			documentTypeTag, // *
			getInvoiceSubTotal, // *?
			toPay,
			correctedTotal, // *?
			roundingAmount, // *?
			invoiceVatArr, // *?
			getInvoicePrice,
			getLinePriceTotalCredit,
		}
	}
	// todo --------------------------------------------------------------------------
}

export default function useCalculateToPayFunctions() {
	return {
		calculateToPay,
	}
}
