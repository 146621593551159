/** @format */

import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'

Vue.use(VueCompositionApi)

import useOrderDocDefinition from '@/_srcv2/pages/_reports/get-data-and-report/order/orderDocDefinition.js'
import useDispatchDocDefinition from '@/_srcv2/pages/_reports/get-data-and-report/dispatch/dispatchDocDefinition.js'
import useInvoiceDocDefinition from '@/_srcv2/pages/_reports/get-data-and-report/invoice/invoiceDocDefinition.js'
import useReminderDocDefinition from '@/_srcv2/pages/_reports/get-data-and-report/reminder/report/reminderDocDefinition.js'

const { getOrderReportData } = useOrderDocDefinition()
const { getDispatchReportData } = useDispatchDocDefinition()
const { getInvoiceReportData } = useInvoiceDocDefinition()
const { getReminderReportData } = useReminderDocDefinition()
const getDocumentDefinition = (docType) => {
	switch (docType) {
		case 'order':
			return getOrderReportData()
		case 'dispatch':
			return getDispatchReportData()
		case 'sale-invoice':
			return getInvoiceReportData()
		case 'reminder':
			return getReminderReportData()
		default:
			alert('Unknown document type')
	}
}
// todo end -------------------------------------------------------
export default function useDocumentDefinition() {
	return {
		getDocumentDefinition,
	}
}
