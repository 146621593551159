/** @format */

// ? To share the state between components write thw following three lines
// ? Otherwise each component creates a new instance of the reactive state object that can not share the state
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import { reactive } from '@vue/composition-api'
// *** Get the data from store ***
import Store from '@/store'
// *** Import functions to format currency and numbers ***
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'

const {
	qr_nme,
	qr_cid,
	qr_iref,
	qr_idt,
	qr_ddt,
	qr_pt,
	qr_due,
	qr_acc,
	qrString,
	slicedOcrNumber,
} = useProcessReportStore()

const { roundedCurrencyAddedNumber } = useReportNumber()

const getReminderReportData = () => {
	// ? Get report data from store
	console.log(
		'From reminderDocDefinition ===> documentData',
		Store.getters.getPdfReportData,
	)
	// ? Reminder Header, lines, totals
	const reminderHeaders =
		Store.getters.getPdfReportData?.reminder_lines?._reminder_headers
	const reminderLines = Store.getters?.getPdfReportData?.reminder_lines
	const reminderTotals =
		Store.getters.getPdfReportData?.reminder_lines?._reminder_headers
			.reminder_headers_reminder_lines_rel_aggregate.aggregate.sum
	console.log('reminderHeaders', reminderHeaders)
	console.log('reminderLines', reminderLines)
	console.log('reminderTotals', reminderTotals)
	console.log('Store.getters.getPdfReportData', Store.getters.getPdfReportData)
	//todo ----------------------------------------------------------
	const documentData = Store.getters.getPdfReportData
	const documentOurCompany = Store.getters.getPdfReportData.company
	const documentCustomer = Store.getters.getPdfReportData.customer
	const documentCustomerAddress =
		Store.getters.getPdfReportData.invoice_customer_address
	// const documentLines =
	// 	Store.getters.getPdfReportData.document_transaction_goods_transactions_rel
	// todo ----------------------------------------------------------------------------
	// ? Get sale-invoice language and exchange unit
	const documentLanguage = documentData.invoice_language
	const exc = documentData.invoice_exchange_unit
	// todo ----------------------------------------------------------------------------
	const relatedDispatchesNumbersArray =
		Store.getters.getPdfReportData.related_dispatches.map(
			(item) => item.dispatch_number,
		)
	const relatedOrdersRaw = Store.getters.getPdfReportData.related_orders.map(
		(item) => item.order_number,
	)
	const relatedDispatches = String(relatedDispatchesNumbersArray).replaceAll(
		',',
		', ',
	)
	const relatedOrders = String(relatedOrdersRaw).replaceAll(',', ', ')
	// ** ----------------------------------------------------------------------------
	let invoiceRule = ''
	let sendInvoiceBy = ''
	const routeId = documentCustomerAddress._cities.route_id_by_city
	const invoiceNote = () => {
		if (documentCustomer.customer_invoice_rule === 'monthly') {
			invoiceRule = 'M'
		} else {
			invoiceRule = 'D'
		}
		if (documentCustomer.send_invoice_by === 'by e-mail') {
			sendInvoiceBy = 'Email'
		} else if (documentCustomer.send_invoice_by === 'by post') {
			sendInvoiceBy = 'Post'
		} else {
			sendInvoiceBy = 'Leverans'
		}
		return `${documentTypeTag} => ${invoiceRule} - ${sendInvoiceBy} - ${routeId}`
	}
	// ** --------------------------------------------------------------------------------
	// ? Get Bank account
	const bankAccountNumber = documentOurCompany.company_bank_giro
	const ibanNumber = `${documentOurCompany.bic_code}  -  ${documentOurCompany.iban_code}`
	// let invoiceTag
	let documentTypeTag
	let dateIssuedTag
	let dueDateTag
	let customerNumberTag
	let fromTag
	let ourRefTag
	let addressTag
	let toTag
	let yourRefTag
	let orgNumberTag
	let remarksTag
	let contactTag
	let telTag
	let emailTag
	let productUnitTag
	let qtyTag
	let priceTag
	let taxTag
	let totalTag
	let subTotalTag
	let toPayTag
	let messageTag
	let pageTag
	let ofTag
	// let roundingTag
	let relatedDispatchTag
	let relatedOrdersTag
	let notesLine4Tag
	let descriptionToCustomer
	let descriptionToCustomer2
	let reminderFeeTag
	let reminderNumDatumTag
	let reminderDueDateTag
	let reminderAmountTag
	let paymentTag
	let balanceTag
	switch (documentLanguage) {
		case 'en':
			// invoiceTag = 'Invoice'
			documentTypeTag = 'Reminder' // `${dTypeTag}${vatTag}${creditTag}${invoiceTag}`
			dateIssuedTag = 'Issued Date'
			dueDateTag = 'Due Date'
			customerNumberTag = 'Customer Number'
			fromTag = 'From'
			ourRefTag = 'Our ref: '
			addressTag = 'Address'
			toTag = 'To'
			yourRefTag = 'Your ref: '
			orgNumberTag = 'Tax. Number: '
			remarksTag = 'Remarks'
			contactTag = 'Contact '
			telTag = 'Tel: '
			emailTag = ' Email: '
			productUnitTag = 'item - amount'
			qtyTag = 'Qty'
			priceTag = 'Price'
			taxTag = 'VAT'
			totalTag = 'Total'
			subTotalTag = 'Subtotal'
			toPayTag = 'TO PAY'
			messageTag = 'Message'
			pageTag = 'page'
			ofTag = ' of'
			// roundingTag = 'rounding of the price'
			relatedDispatchTag = 'Related dispatch-lists: '
			relatedOrdersTag = 'Related Orders: '
			notesLine4Tag = invoiceNote()
			descriptionToCustomer = `Pay attention Please!
			Write the invoice number (${slicedOcrNumber.value}) as message when you pay`
			descriptionToCustomer2 = `This reminder does not show payments made in the last 2 days.
				Write (${slicedOcrNumber.value}) as a message when you pay.
				Please pay the following invoice(s) by the specified date: ${reminderHeaders.reminder_due_date}.`
			reminderFeeTag = 'A reminder fee will be charged on the upcoming invoice'
			reminderNumDatumTag = 'InvoiceNumber - Date'
			reminderDueDateTag = 'Due Date'
			reminderAmountTag = 'Invoice Amount'
			paymentTag = 'Payments'
			balanceTag = 'Balance'
			break
		default:
			// invoiceTag = 'Faktura'
			documentTypeTag = 'Påminnelse' // `${dTypeTag}${vatTag}${creditTag}${invoiceTag}`
			dateIssuedTag = 'Datum'
			dueDateTag = 'Oss tillhanda'
			customerNumberTag = 'Kundnummer'
			fromTag = 'Från'
			ourRefTag = 'Vår Ref: '
			addressTag = 'Adress'
			toTag = 'Till'
			yourRefTag = 'Err Ref: '
			orgNumberTag = 'Org Nummer: '
			remarksTag = 'Anteckningarna'
			contactTag = 'Kontakt '
			telTag = 'Tel: '
			emailTag = ' Epost: '
			productUnitTag = 'artikel -enhet'
			qtyTag = 'antal'
			priceTag = 'pris'
			taxTag = 'moms'
			totalTag = 'total'
			subTotalTag = 'subtotal'
			toPayTag = 'Att betala'
			messageTag = 'Meddelande'
			pageTag = 'sida'
			ofTag = ' av'
			relatedDispatchTag = 'Relaterade följesedlar: '
			relatedOrdersTag = 'Relaterade order: '
			notesLine4Tag = invoiceNote()
			descriptionToCustomer = `Vänligen betala innan förfallodatum. Vid eventuell försenad betalning kommer ni att debiteras
			Dröjsmålsränta: 8,00 % + referensränta - Inkassoarvode: 180,00 kr.`
			descriptionToCustomer2 = `Denna påminnelse visar ej betalningar gjorda de senaste 2 dagar.
        Skriv (${slicedOcrNumber.value}) som meddelande när ni betalar
        Följande fakturor är obetalda och kommer och överlämnas till INKASSO.
        Vänligen betala följande faktura/fakturor till angivna datum: ${reminderHeaders.reminder_due_date}.
        Dröjsmålsränta: 8,00 % + referensränta - Inkassoarvode: 180,00 kr. kan tillkomma.`
			reminderFeeTag =
				'påminnelseavgift kommer att debiteras på kommande faktura'
			reminderNumDatumTag = 'Faktura Nummer - Datum'
			reminderDueDateTag = 'Förfallodatum'
			reminderAmountTag = 'Faktura belopp'
			paymentTag = 'Betalningar'
			balanceTag = 'Saldo'
	}
	const data = {
		footerLeft: 'Godkänd för F-skatt',
		footerCenter: 'myCenterFooter',
		logo: documentOurCompany.company_logo_64base,
		watermark: documentOurCompany.company_watermark_64base,
		documentType: documentTypeTag,
		documentNumber: reminderHeaders.reminder_id,
		dateIssued: reminderHeaders.reminder_date,
		dateIssuedLabel: dateIssuedTag,
		dueDate: reminderHeaders.reminder_due_date,
		dueDateLabel: dueDateTag,
		bankGiroLabel: 'Bankgiro',
		ibanLabel: 'BIC - IBAN',
		messageLabel: messageTag,
		customerNumber: documentCustomer.customer_id,
		customerNumberLabel: customerNumberTag,
		from: fromTag,
		billingFromLabel: fromTag,
		ourRef: ourRefTag,
		reference: documentOurCompany.company_reference,
		ourCompany: documentOurCompany.company_name,
		addressLabel: addressTag,
		addressLine1:
			documentOurCompany.company_address_line_1 +
			' ' +
			documentOurCompany.company_address_line_2,
		addressLine2:
			documentOurCompany.company_post_code +
			' ' +
			documentOurCompany.company_city,
		addressLine3:
			documentOurCompany.company_state +
			' ' +
			documentOurCompany.company_country,
		addressLine4: '',
		orgNum: documentOurCompany.company_org_num,
		to: toTag,
		billingToLabel: toTag,
		yourRef: yourRefTag,
		toReference: documentCustomer.customer_reference,
		clientCompany: documentCustomer.customer_title,
		clientNickName: documentCustomer.customer_nickname,
		toAddressLine1:
			documentCustomerAddress.line_1 + ' ' + documentCustomerAddress.line_2,
		toAddressLine2:
			documentCustomerAddress.post_code + ' ' + documentCustomerAddress.city,
		toAddressLine3:
			documentCustomerAddress.state + ' ' + documentCustomerAddress.country,
		toAddressLine4: '',
		toOrgNum: documentCustomer.customer_org_num,
		reminderLines: reminderLines,
		reminderSubTotal: roundedCurrencyAddedNumber(
			reminderTotals.invoice_balance,
		),
		remindersLinesVatArr: '',
		reminderTotal: roundedCurrencyAddedNumber(
			reminderTotals.invoice_balance, // + reminderData.reminder_fee
		),
		orgNumberLabel: orgNumberTag,
		notesLabel: remarksTag,
		notesLine1:
			contactTag +
			telTag +
			documentOurCompany.company_tel +
			emailTag +
			documentOurCompany.company_email,
		notesLine2: relatedDispatches,
		notesLine3: relatedOrders,
		relatedDispatchLabel: relatedDispatchTag,
		relatedOrderLabel: relatedOrdersTag,
		notesLine4: notesLine4Tag,
		productUnitLabel: productUnitTag,
		qty: qtyTag,
		price: priceTag,
		tax: taxTag,
		total: totalTag,
		subTotalLabel: subTotalTag,
		totalLabel: toPayTag,
		page: pageTag,
		of: ofTag,
		exchange: exc,
		reminderFeeLabel: reminderFeeTag,
		paymentDescription: descriptionToCustomer,
		paymentDescription2: descriptionToCustomer2,
		// todo *****************************************
		reminderNumDatumLabel: reminderNumDatumTag,
		reminderDueDateLabel: reminderDueDateTag,
		reminderAmountLabel: reminderAmountTag,
		paymentLabel: paymentTag,
		balanceLabel: balanceTag,
		// subTotalLabel: subTotalTag,
		reminderFee: roundedCurrencyAddedNumber(reminderHeaders.reminder_fee),
	}
	// todo create qr code -----------------------------
	const qrContent = reactive({
		uqr: 1,
		tp: 1,
		nme: qr_nme,
		cid: qr_cid, // ** Kundnummer
		iref: qr_iref, // ** Fakturanummer
		idt: qr_idt, // ** Fakturadatum
		ddt: qr_ddt, // ** Förfallodatutm
		due: qr_due, // ** Att betala
		pt: qr_pt, // ** BG or PG
		acc: qr_acc, // ** Kontonummer
	})
	qrString.value = JSON.stringify(qrContent)
	// todo end -------------------------------
	const docDefinition = reactive({
		pageSize: 'A4',
		pageOrientation: 'portrait',
		pageMargins: [50, 20, 40, 70],
		footer: function (currentPage, pageCount) {
			return {
				columns: [
					{ text: data.footerLeft, style: 'documentFooterLeft' },
					{
						text: data.page + currentPage.toString() + data.of + pageCount,
						style: 'documentFooterRight',
					},
				],
			}
		},
		background: [
			{
				image: data.watermark,
				opacity: 0.3,
				alignment: 'center',
				height: 264,
				margin: [0, (842 - 264) / 2, 0, 0],
			},
			{
				canvas: [
					{
						type: 'line',
						x1: 50,
						y1: 245,
						x2: 570,
						y2: 245,
						lineWidth: 1,
					},
				],
			},
		],
		content: [],
		styles: {
			documentFooterLeft: {
				fontSize: 12,
				bold: true,
				margin: [50, 30, 5, 40],
				alignment: 'left',
			},
			documentFooterLineLeft: {
				fontSize: 10,
				margin: [50, 0, 5, 0],
				alignment: 'left',
			},
			documentFooterLineCenter: {
				fontSize: 10,
				margin: [0, 0, 5, 0],
				alignment: 'center',
			},
			documentFooterLineRight: {
				fontSize: 10,
				margin: [0, 0, 5, 50],
				alignment: 'right',
			},
			documentFooterRight: {
				fontSize: 10,
				margin: [5, 30, 40, 40],
				alignment: 'right',
			},
			// Document Title
			documentTitle: {
				fontSize: 18,
				bold: true,
				alignment: 'right',
				margin: [0, 0, 0, 15],
			},
			// Document Details
			documentSubTitle: {
				fontSize: 10,
				alignment: 'right',
			},
			documentSubValue: {
				fontSize: 10,
				alignment: 'right',
				bold: true,
			},
			// Billing Headers
			documentBillingTitle: {
				fontSize: 11,
				bold: true,
				alignment: 'left',
				margin: [0, 10, 0, 5],
			},
			// Billing Details
			documentBillingCompanyDetails: {
				alignment: 'left',
				fontSize: 11,
				bold: true,
				margin: [50, -18, 0, 0],
			},
			documentBillingDetails: {
				alignment: 'left',
				fontSize: 10,
				margin: [50, 0, 0, 0],
			},
			documentBillingAddressTitle: {
				margin: [50, 2, 0, 1],
				bold: true,
				fontSize: 10,
			},
			documentBillingAddress: {
				fontSize: 10,
				margin: [50, 0, 0, 0],
			},
			organizationNumber: {
				margin: [50, 0, 0, -15],
				bold: true,
				fontSize: 10,
			},
			dispatchNumber: {
				margin: [0, 6, 0, 6],
				bold: true,
				fontSize: 11,
			},
			// to pay
			cellsToPay: {
				margin: [5, 1, 5, 2],
				bold: true,
				fontSize: 10,
			},
			cellsToPayExtraTopMargin: {
				margin: [5, 5, 5, 2],
				bold: true,
				fontSize: 10,
			},
			qrStyle: {
				margin: [0, -95, 0, 5],
				bold: false,
				fontSize: 9,
				alignment: 'right',
			},
			tableToPay: {
				margin: [0, 2, 0, 0],
			},
			// Items Header
			itemsHeader: {
				margin: [0, 5, 0, 5],
				bold: true,
				fontSize: 10,
			},
			// Item Title
			itemTitle: {
				bold: true,
				fontSize: 10,
			},
			itemSubTitle: {
				italics: true,
				fontSize: 10,
			},
			itemNumberTotal: {
				margin: [0, 5, 10, 5],
				alignment: 'right',
				fontSize: 10,
			},
			itemNumberPrice: {
				margin: [2, 5, 2, 5],
				alignment: 'right',
				fontSize: 10,
			},
			itemNumberA: {
				margin: [0, 5, 0, 5],
				alignment: 'left',
				fontSize: 10,
			},
			itemNumberC: {
				margin: [0, 5, 0, 5],
				alignment: 'center',
				fontSize: 10,
			},
			itemTotal: {
				margin: [0, 5, 0, 5],
				bold: true,
				alignment: 'center',
				fontSize: 10,
			},
			// Items Footer (Subtotal, Total, Tax, etc)
			itemsFooterSubTitle: {
				margin: [0, 5, 0, 0],
				bold: true,
				alignment: 'right',
				fontSize: 10,
			},
			itemsFooterSubValue: {
				margin: [0, 5, 13, 0],
				bold: true,
				alignment: 'right', // center
				fontSize: 10,
			},
			itemsFooterTotalTitle: {
				margin: [0, 3, 0, 5],
				bold: true,
				alignment: 'right',
				fontSize: 10,
			},
			itemsFooterTotalValue: {
				margin: [0, 3, 12, 5], // *******************
				bold: true,
				alignment: 'right',
				fontSize: 10,
			},
			signaturePlaceholder: {
				margin: [0, 70, 0, 0],
				fontSize: 10,
			},
			signatureName: {
				bold: true,
				alignment: 'center',
				fontSize: 10,
			},
			signatureJobTitle: {
				italics: true,
				fontSize: 10,
				alignment: 'center',
			},
			notesTitle: {
				fontSize: 10,
				bold: true,
				margin: [0, 10, 0, 3],
			},
			notesTitleTop: {
				fontSize: 12,
				bold: true,
				margin: [0, 0, 0, 3],
			},
			notesText: {
				fontSize: 10,
			},
			notesTextBold: {
				fontSize: 12,
				bold: true,
			},
			descriptionToCustomer: {
				fontSize: 10,
				bold: false,
				margin: [0, -12, 0, 7],
			},
			center: {
				alignment: 'center',
			},
			itemVat: {
				margin: [2, 5, 2, 5],
				alignment: 'right',
				fontSize: 10,
			},
			itemVatAmount: {
				margin: [0, 5, 14, 5],
				alignment: 'right',
				fontSize: 10,
			},
			paymentTableStyle: {
				margin: [0, 10, 0, 0],
			},
		},
		tableHeader: {
			bold: true,
			alignment: 'center',
			fontSize: 10,
		},
		cellsToPay2: {
			margin: [10, 5, 0, 5],
			bold: true,
			fontSize: 13,
		},
		defaultStyle: {
			columnGap: 20,
		},
	})
	// ********************** PUSH CONTENT BEGIN********************************************************
	const header = {
		columns: [
			{
				image: data.logo,
				width: 70,
			},
			[
				{
					text: data.documentType,
					style: 'documentTitle',
					width: '*',
				},
				{
					stack: [
						{
							columns: [
								{
									text: data.documentType + ' #',
									style: 'documentSubTitle',
									width: '*',
								},
								{
									text: data.documentNumber,
									style: 'documentSubValue',
									width: 100,
								},
							],
						},
						{
							columns: [
								{
									text: data.dateIssuedLabel,
									style: 'documentSubTitle',
									width: '*',
								},
								{
									text: data.dateIssued,
									style: 'documentSubValue',
									width: 100,
								},
							],
						},
						{
							columns: [
								{
									text: data.customerNumberLabel,
									style: 'documentSubTitle',
									width: '*',
								},
								{
									text: data.customerNumber,
									style: 'documentSubValue',
									width: 100,
								},
							],
						},
					],
				},
			],
		],
	}
	const billingHeaders = {
		columns: [
			{
				text: data.billingFromLabel,
				style: 'documentBillingTitle',
			},
			{
				text: data.billingToLabel,
				style: 'documentBillingTitle',
			},
		],
	}
	const billingDetails = {
		columns: [
			{
				text: data.ourCompany,
				style: 'documentBillingCompanyDetails',
			},
			{
				text: data.clientCompany,
				style: 'documentBillingCompanyDetails',
			},
		],
	}
	const references = {
		columns: [
			{
				text: data.ourRef + data.reference,
				style: 'documentBillingDetails',
			},
			{
				text: data.yourRef + data.toReference,
				style: 'documentBillingDetails',
			},
		],
	}
	const nicknames = {
		columns: [
			{
				text: '',
				style: 'documentBillingDetails',
			},
			{
				text: data.clientNickName,
				style: 'documentBillingDetails',
			},
		],
	}
	const addressTitle = {
		columns: [
			{
				text: data.addressLabel,
				style: 'documentBillingAddressTitle',
			},
			{
				text: data.addressLabel,
				style: 'documentBillingAddressTitle',
			},
		],
	}
	const billingAddress = {
		columns: [
			{
				text:
					data.addressLine1 +
					' \n ' +
					data.addressLine2 +
					' \n ' +
					data.addressLine3 +
					' \n ',
				style: 'documentBillingAddress',
			},
			{
				text:
					data.toAddressLine1 +
					' \n ' +
					data.toAddressLine2 +
					' \n ' +
					data.toAddressLine3 +
					' \n ',
				style: 'documentBillingAddress',
			},
		],
	}
	const orgNumber = {
		columns: [
			{
				text: data.orgNumberLabel + data.orgNum,
				style: 'organizationNumber',
			},
			{
				text: data.orgNumberLabel + data.toOrgNum,
				style: 'organizationNumber',
			},
		],
	}
	const horizontalLine = {
		table: {
			widths: ['*'],
			body: [[' '], [' ']],
		},
		layout: 'lightHorizontalLines',
	}
	// **********************************************************************************
	const toPayInfo = {
		stack: [
			{
				style: 'tableToPay',
				table: {
					widths: ['auto', 'auto', 'auto'],
					body: [
						[
							{
								text: toPayTag,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'left',
								style: 'cellsToPayExtraTopMargin',
							},
							{
								text: data.reminderTotal,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'right',
								style: 'cellsToPayExtraTopMargin',
							},
							{
								text: '',
								alignment: 'left',
								style: 'cellsToPayExtraTopMargin',
							},
						],
						[
							{
								text: data.dueDateLabel,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'left',
								style: 'cellsToPay',
							},
							{
								text: data.dueDate,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'right',
								style: 'cellsToPay',
							},
							{
								text: '',
								alignment: 'left',
								style: 'cellsToPay',
							},
						],
						[
							{
								text: data.bankGiroLabel,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'left',
								style: 'cellsToPay',
							},
							{
								text: bankAccountNumber,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'right',
								style: 'cellsToPay',
							},
							{
								text: '',
								alignment: 'left',
								style: 'cellsToPay',
							},
						],
						[
							{
								text: data.messageLabel,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'left',
								style: 'cellsToPay',
							},
							{
								text: slicedOcrNumber.value,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'right',
								style: 'cellsToPay',
							},
							{
								text: '',
								alignment: 'left',
								style: 'cellsToPay',
							},
						],
						[
							{
								text: data.ibanLabel,
								color: '#555555',
								fillColor: '#e6a5a5',
								alignment: 'left',
								style: 'cellsToPay',
							},
							{
								text: ibanNumber,
								color: '#555555',
								fillColor: '#e6a5a5',
								alignment: 'right',
								style: 'cellsToPay',
							},
							{
								text: '',
								alignment: 'left',
								style: 'cellsToPay',
							},
						],
					],
				},
				layout: 'noBorders',
				defaultStyle: {
					alignment: 'left',
				},
			},
			{
				qr: JSON.stringify(qrContent),
				fit: '100',
				style: 'qrStyle',
			},
		],
	}
	// todo --------------------------------------------------------------------------
	// *** Set the array to push to table headers in the report ***
	const rowsItems = []
	rowsItems.push([
		{
			text: data.reminderNumDatumLabel,
			style: 'itemsHeader',
		},
		{
			text: data.reminderDueDateLabel,
			style: ['itemsHeader', 'center'],
		},
		{
			text: data.reminderAmountLabel,
			style: ['itemsHeader', 'center'],
		},
		{
			text: data.paymentLabel,
			style: ['itemsHeader', 'center'],
		},
		{
			text: data.balanceLabel,
			style: ['itemsHeader', 'center'],
		},
	])
	// *** Set the array to push to table rows in the report ***
	rowsItems.push([
		{
			text: reminderLines.invoice_number + ' - ' + reminderLines.invoice_date,
			style: 'itemNumberA',
		},
		{
			text: reminderLines.invoice_due_date,
			style: 'itemNumberC',
		},
		{
			text: roundedCurrencyAddedNumber(reminderLines.invoice_to_pay),
			style: 'itemNumberPrice',
		},
		{
			text: roundedCurrencyAddedNumber(reminderLines.invoice_payment),
			style: 'itemNumberPrice',
		},
		{
			text: roundedCurrencyAddedNumber(reminderLines.invoice_balance),
			style: 'itemNumberTotal',
		},
	])
	const remindedInvoicePart0 = {
		style: 'tableToPay2',
		table: {
			widths: ['100%'],
			body: [
				[
					{
						text: data.paymentDescription2,
						color: '#555555',
						fillColor: '#dedede',
						alignment: 'left',
						style: 'cellsToPayExtraTopMargin',
					},
				],
				[
					{
						text: '',
						alignment: 'left',
						color: '#555555',
						fillColor: '#dedede',
						style: 'cellsToPayExtraTopMargin',
					},
				],
			],
		},
		layout: 'noBorders',
		defaultStyle: {
			alignment: 'right',
		},
	}
	const remindedInvoicePart1 = {
		table: {
			// headers are automatically repeated if the table spans over multiple pages
			// you can declare how many rows should be treated as headers
			headerRows: 1,
			widths: ['*', 80, 80, 80, 80],
			style: 'tableHeader',
			body: rowsItems,
		},
	}
	const remindedInvoicePart2 = {
		table: {
			// headers are automatically repeated if the table spans over multiple pages
			// you can declare how many rows should be treated as headers
			headerRows: 0,
			widths: ['*', 80],

			body: [
				// Total
				[
					{
						text: data.totalLabel,
						style: 'itemsFooterTotalTitle',
					},
					{
						text: data.reminderTotal, // text: data.reminderFee,
						style: 'itemsFooterTotalValue', // style: 'itemsFooterTotalValue'
					},
				],
				[
					{
						text: data.reminderFeeLabel,
						style: 'itemsFooterTotalTitle',
					},
					{
						text: data.reminderFee, // text: data.reminderTotal,
						style: 'itemsFooterTotalValue', // style: 'itemsFooterTotalValue'
					},
				],
			],
		}, // table
		layout: 'lightHorizontalLines',
	}
	const emptyLine = {
		text: '',
		margin: [0, 10, 0, 0],
	}
	// **********************************************************************************
	const notes = {
		text: `${data.paymentDescription}`,
		style: 'descriptionToCustomer',
	}
	const invoiceNotesStack = {
		stack: [
			{
				text: data.notesLabel,
				style: 'notesTitle',
			},
			{
				text: `${data.notesLine1}
      ${data.relatedDispatchLabel} ${data.notesLine2}
      ${data.relatedOrderLabel} ${data.notesLine3}
			${data.notesLine4}
			`,
				style: 'notesText',
			},
		],
		unbreakable: true,
	}
	docDefinition.content.push(header)
	docDefinition.content.push(billingHeaders)
	docDefinition.content.push(billingDetails)
	docDefinition.content.push(references)
	docDefinition.content.push(nicknames)
	docDefinition.content.push(addressTitle)
	docDefinition.content.push(billingAddress)
	docDefinition.content.push(orgNumber)
	docDefinition.content.push(horizontalLine)
	// ** ----------------------------------------------------------------
	docDefinition.content.push(notes)
	docDefinition.content.push(toPayInfo)
	// ** ----------------------------------------------------------------
	docDefinition.content.push(remindedInvoicePart0)
	docDefinition.content.push(emptyLine)
	docDefinition.content.push(remindedInvoicePart1)
	docDefinition.content.push(remindedInvoicePart2)
	docDefinition.content.push(horizontalLine)
	// ** ----------------------------------------------------------------
	docDefinition.content.push(invoiceNotesStack)
	// ********************** PUSH CONTENT END********************************************************
	return docDefinition
}

export default function useReminderDocDefinition() {
	return {
		getReminderReportData,
	}
}
