<!-- @format -->

<template>
	<div>
		<article
			v-if="!!isDocumentMailable"
			class="tile is-child notification is-info"
			:class="{ shake: isDocumentMailable }"
			style="width: 550px; margin-bottom: 30px"
		>
			<div>
				<div class="big-font">
					<b
						>Send {{ props.reportDocumentType }}:&ensp;{{
							props.sendDocumentBy
						}}</b
					>
				</div>
				<div v-if="props.hasValidEmail" class="middle-font">
					email:&nbsp;
					{{ props.reportCustomerEmail }}
				</div>
				<div v-if="!props.hasValidEmail" class="middle-font">
					<span>Customer's email address is not valid</span>
				</div>
			</div>
		</article>
		<div>
			<b-button
				class="is-info margin-top-15"
				@click="props.openPDF()"
				style="width: 250px"
				><i class="fas fa-search"></i>&nbsp; Preview
			</b-button>
		</div>
		<div>
			<b-button
				class="is-success margin-top-15"
				@click="props.downloadPDF()"
				style="width: 250px"
				><i class="fas fa-download"></i>&nbsp; Download</b-button
			>
		</div>
		<div>
			<b-button
				class="margin-top-15"
				@click="props.printPDF()"
				style="width: 250px; background-color: rgb(121, 87, 213); color: white"
				><i class="fas fa-print" style="text-align: left"></i>&nbsp;
				Print</b-button
			>
		</div>
		<div v-if="isDocumentMailable">
			<b-button
				:disabled="!props.hasValidEmail"
				class="is-primary margin-top-15"
				@click="props.emailPDF"
				style="width: 250px"
				><i class="fas fa-envelope-square"></i>&nbsp; Email</b-button
			>
		</div>
		<div v-if="isValidDocType">
			<div
				v-if="!isDocumentMailable"
				style="
					font-weight: bolder;
					font-size: larger;
					color: red;
					margin-top: 20px;
				"
			>
				This customer has no email or not to send by e-mail!!!
			</div>
		</div>
	</div>
</template>
<script>
import { computed, onMounted } from '@vue/composition-api'
import alarmsound from '@/assets/sounds/alarm.mp3'

export default {
	name: 'ReportButtons',
	props: {
		reportCustomerEmail: {
			type: String,
		},
		openPDF: {
			type: Function,
		},
		downloadPDF: {
			type: Function,
		},
		printPDF: {
			type: Function,
		},
		emailPDF: {
			type: Function,
		},
		hasValidEmail: {
			type: Boolean,
		},
		reportDocumentType: {
			type: String,
		},
		sendDocumentBy: {
			type: String,
		},
	},
	setup(props) {
		// todo ----------------------------------------------------------------
		const isDocumentMailable = computed(() => {
			if (
				props.reportDocumentType === 'sale-invoice' &&
				props.sendDocumentBy === 'by e-mail'
			) {
				return true
			} else if (
				props.reportDocumentType === 'reminder' &&
				props.sendDocumentBy === 'by e-mail'
			) {
				return true
			} else if (props.reportDocumentType === 'order') {
				return false
			} else if (props.reportDocumentType === 'dispatch') {
				return false
			} else {
				return false
			}
		})
		// todo enable disable controllers ----------------------------------
		const playSound = (sound) => {
			if (sound) {
				var audio = new Audio(sound)
				audio.play()
			}
		}
		onMounted(() => {
			console.log('--- onMounted hasValidEmail', props.hasValidEmail.value)
			if (props.hasValidEmail.value && isDocumentMailable.value) {
				playSound(alarmsound)
			}
			console.log('---- isDocumentMailable', isDocumentMailable.value)
		})
		const isValidDocType = computed(() => {
			return props.reportDocumentType === 'sale-invoice' ||
				props.reportDocumentType === 'reminder'
				? true
				: false
		})
		// ? ----------------------------------------------------------------
		return { props, isDocumentMailable, isValidDocType }
	},
}
</script>
<style scoped>
.big-font {
	font-weight: bolder;
	font-size: 2em;
}
.middle-font {
	font-weight: bold;
	font-size: 1.5em;
}
.sendmail {
	background-color: red;
}
.shake {
	background-color: red;
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
</style>
